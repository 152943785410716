import React from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import Img from "gatsby-image";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { PageTitle } from "../components/pageTitle";
import Hero from "../components/hero";
import RichText from "../components/richText";
import { ptSansStack } from "../tokens/typography";
import Testimonial from "../components/testimonial";
import { focusState } from "../tokens/a11y";

function StandardPageTemplate({ data }) {
  const {
    title,
    body,
    slug,
    childContent = null,
    image = null,
  } = data.contentfulPage;
  const { json } = body;
  const breadcrumbs = [{ name: "Home", href: "/" }, { name: title }];

  const filteredServices =
    childContent &&
    childContent.length > 0 &&
    childContent.filter(item => item.__typename === "ContentfulService");
  const filteredTestimonials =
    childContent &&
    childContent.length > 0 &&
    childContent.filter(item => item.__typename === "ContentfulTestimonial");
  return (
    <Layout
      hero={
        <Hero breadcrumbs={breadcrumbs}>
          <PageTitle id="mainContent">{title}</PageTitle>
        </Hero>
      }
    >
      <SEO title={title} />
      {image && <HeroImg fluid={image.fluid} />}
      <MaxWidthBox>
        <RichText>{documentToReactComponents(json)}</RichText>
      </MaxWidthBox>
      {filteredServices && (
        <Cards>
          {filteredServices.map(({ slug: childSlug, title, id, image }) => (
            <ImageCard
              key={id}
              to={`/${slug}/${childSlug}/`}
              style={{ display: "block" }}
            >
              <Img fluid={image.fluid} />
              <ImageTitle>{title}</ImageTitle>
            </ImageCard>
          ))}
        </Cards>
      )}
      {filteredTestimonials && (
        <Testimonials>
          {filteredTestimonials.map(({ id, quote, author }) => (
            <Testimonial key={id} quote={quote} author={author} />
          ))}
        </Testimonials>
      )}
    </Layout>
  );
}

export default StandardPageTemplate;

export const pageQuery = graphql`
  query PageById($id: String!) {
    contentfulPage(id: { eq: $id }) {
      title
      slug
      body {
        json
      }
      image {
        fluid(maxWidth: 1200) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      childContent {
        ... on ContentfulService {
          __typename
          id
          slug
          title
          image {
            fluid(maxWidth: 600) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
        ... on ContentfulTestimonial {
          __typename
          id
          quote {
            json
          }
          author
        }
      }
    }
  }
`;

const MaxWidthBox = styled.div`
  max-width: 60ch;
`;

const HeroImg = styled(Img)`
  margin-right: -1rem;
  margin-bottom: 1.5rem;
  margin-left: -1rem;

  @media (min-width: 32em) {
    margin-right: -2rem;
    margin-left: -2rem;
  }

  @media (min-width: 50em) {
    margin-right: -2rem;
    margin-left: -3rem;
  }
`;

const Cards = styled.div`
  margin-right: -1rem;
  margin-left: -1rem;

  @media (min-width: 70em) {
    display: flex;
    flex-wrap: wrap;
  }
`;

const ImageCard = styled(Link)`
  position: relative;
  display: block;
  margin-top: 2rem;
  padding-right: 1rem;
  padding-left: 1rem;

  &:focus {
    ${focusState(({ theme }) => theme.palette.darkerLightBlue)};
  }

  @media (min-width: 70em) {
    width: 50%;
  }
`;

const ImageTitle = styled.h2`
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  margin: 0.5rem 1.5rem;
  line-height: 1;
  font-family: ${ptSansStack};
  color: white;

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: -1rem;
    right: -0.5rem;
    bottom: -0.5rem;
    left: -0.5rem;
    z-index: -1;
    background-image: linear-gradient(
      rgba(0, 0, 0, 0) 5%,
      rgba(0, 0, 0, 0.5) 40%,
      rgba(0, 0, 0, 0.8)
    );
  }
`;

const Testimonials = styled.div`
  display: flex;
  flex-direction: column;
`;
